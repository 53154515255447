import {CSSProperties, useEffect, useState} from 'react';
import styled from 'styled-components'
import moment from 'moment';
import { PuffLoader } from 'react-spinners';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd'
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import dayjs from 'dayjs';
import TableDynamicPage from './components/TableDynamicPage';
moment().format();


const translateKeys = (data) => {
  return data.map(entry => {
    const translatedEntry: any = {};
    for (const key in entry) {
      switch (key) {
        case 'Наличность':
          translatedEntry.cash = entry[key];
          break;
        case 'Общая выручка':
          translatedEntry.revenue = entry[key];
          break;
        case 'Открытые столы':
          translatedEntry.tables = entry[key];
          break;
        case 'Чистая выручка':
          translatedEntry.net = entry[key];
          break;
        case 'Платежные карты':
          translatedEntry.cards = entry[key];
          break;
        case 'Кредитные карты':
          translatedEntry.credit = entry[key];
          break;
        default:
          translatedEntry[key] = entry[key]; // Keeps the original value for keys that don't need translation
      }
    }
    return translatedEntry;
  });
};

const DynamicPage = () => {
  const [tableData, setTableData] = useState(undefined);
  const [tableDataHour, setTableDataHour] = useState(undefined);
  const [loading, setLoading] = useState(true)
  const [datePickerValue, setDatePickerValue] = useState<any>('2024-06-01')
  const [valueRadio, setValueRadio] = useState(true)

  useEffect(() => {
   const url = `https://stage.proga.pro/api/shops/1/reports/revenue/hour/days?date=${datePickerValue}`
      Promise.all([
        fetch(url, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
  
          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          if (data && data[0] && Array.isArray(data[0].data)) {
            const formedData = translateKeys(data[0].data)
            const formatedData = formedData.map((i, ind) => {
              return {
                key: ind,
                period: i.period,
                cards: (i.cards)? i.cards: 0,
                cash: (i.cash)? i.cash :0,
                credit: (i.credit)? i.credit: 0,
                tables: (i.tables)? i.tables: 0,
                revenue: (i.revenue)? i.revenue: 0,
                net: (i.net)? i.net: 0,
              }
            })
            const result = formatedData.map((item, index, arr) => {
              if (index === 0) {

                return {
                  key: index,
                  period: item.period,
                  cards: (item.cards)? item.cards: 0,
                  cash: (item.cash)? item.cash :0,
                  credit: (item.credit)? item.credit: 0,
                  tables: (item.tables)? item.tables: 0,
                  revenue: (item.revenue)? item.revenue: 0,
                  net: (item.net)? item.net: 0,
                };
                
              } else {

                const lastValue = arr[index - 1];
                return {
                  key: index,
                  period: item.period,
                  cards: (item.cards)? item.cards - lastValue.cards: 0,
                  cash: (item.cash)? item.cash - lastValue.cash: 0,
                  credit: (item.credit)? item.credit - lastValue.credit: 0,
                  tables: item.tables - lastValue.tables,
                  revenue: (item.revenue)? item.revenue - lastValue.revenue: 0,
                  net: (item.net)? item.net - lastValue.net: 0,
                };
              }
              
            });
            setTableDataHour(formatedData)
            setTableData(result)
            setLoading(false)

          } else {
            setTableData(undefined)
            setLoading(false)
          }

        })

    
  }, [datePickerValue])
  
  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current > moment().utc().endOf('day'))
  }

  const onDateChange: DatePickerProps['onChange'] = (date: any, dateString: any) => {
    
    if (dateString) {
      setDatePickerValue(dateString.toString());
      setLoading(true);
    } else {

    }
  };
  
  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const handleChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          Динамика
        </Title>
        <Radio.Group value={valueRadio} onChange={handleChange}>
          <Radio.Button value={false}>С накоплением</Radio.Button>
          <Radio.Button value={true}>По часам</Radio.Button>
        </Radio.Group>
        <StyledCaledarWrapper>
          <DatePicker
              onChange={onDateChange}
              value={dayjs(datePickerValue)}
              format={'YYYY-MM-DD'}
              // placeholder={datePickerValue}
              disabledDate={disabledDate}
          />

        </StyledCaledarWrapper>
      </Header>
      <div>
        {loading ? (
          <PuffLoader color="#E65E20" cssOverride={cssOverride} />
        ) : (<TableDynamicPage
            data={(valueRadio)?tableData: tableDataHour}
            loading={loading}
            valueRadio={valueRadio}
        />)}
      </div>
    </Wrapper>
   
  )
}

export default DynamicPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: #fff;
  padding: 16px 0px 16px 0px;

  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td {
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;
  }
`

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  margin-bottom: 20px;
  /* gap: 30px; */
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const StyledCaledarWrapper = styled.div`
  width: 250px;
  color: #ffffff;
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 9px;
    /* color: #ffffff !important; */
  }


`