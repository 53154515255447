/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useEffect, useState } from 'react';

const useFetchData = <T = any>(url: string, initialParams: any, initialLoading = false) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(undefined);
  const [params, setParams] = useState(initialParams);

  function getData() {
    
    if (!_.isEmpty(url)) {
      setLoading(true);
      
      fetch(url, {
        method: 'GET',
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(resp => resp.json())
        .then(json => {
          setData(json);
          setLoading(false);
          setError(undefined);
        })
        .catch(err => {
          console.log('err', err);
          
          setLoading(false);
          setData(undefined);
          setError(err);
        });
    
    }
  }

  useEffect(() => {
    
    getData();

    return () => {
      setData(undefined);
      setLoading(false);
      setError(undefined);
    }
  }, [ ...params ]);

  return {
    data,
    loading,
    error,
    params,
    setParams,
  };
};

export default useFetchData;
