import React from 'react';
import Layout from '../../components/Layout';

interface PropsRkeeper {
  sidemenu: any;
}

const Rkeeper = ({sidemenu}: PropsRkeeper) => {
  return (
    <Layout sidemenu={ sidemenu }>
      <div >
      </div>

    </Layout>
  )
}

export default Rkeeper;