import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment';
import { Table, Tag, Badge, Tooltip } from 'antd';
import type { TableColumnsType, GetProp, TableProps } from 'antd';
import ClientModal from './components/ClientModal'
import { Icons } from '../../assets';
import Highlighter from 'react-highlight-words';
import {ManOutlined, WomanOutlined} from '@ant-design/icons'

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

moment().format();

const ClientsPage = () => {
  const [openedSettingsPopup, setOpenedSettingsPopup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([])
  const [sortedData, setSortedData] = useState<any[]>([])
  const [selectedClient, setSelectedClient] = useState<any>(undefined)
  const [nameInventedArray, setNameInventedArray] = useState<any[]>([]);
  const [nameCategoriesArray, setNameCategoriesArray] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  // const [hasFetched, setHasFetched] = useState(false);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  
  
  useEffect(() => {
    const searchKeys = ['fio', 'email', 'phone', 'birthdate', 'id']
    console.log(searchText);
    
    const filteredData = _.filter(data, (obj) => {
      return _.some(searchKeys, (key) => _.includes(obj[key].toString().toLowerCase(), searchText.toLowerCase()));
    });
    setSortedData(filteredData)
    
  }, [searchText, data])

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    setSortedData(data)
  }, [data])

  useEffect(() => {
    if (loading ) {
      setLoading(false);
      const clientsUrl = `https://stage.proga.pro/api/shops/1/loyalty/clients`
      Promise.all([
        fetch(clientsUrl, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          if (data && Array.isArray(data) && data.length > 0) {
            setData(data) 
          } else setData([])

        })
      setLoading(false);
      setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
    }
  }, [loading])

  useEffect(() => {setLoading(true)}, [])

  const getColumnSearchProps = dataIndex => ({
  // ... (остальные свойства)
    render: text =>
      searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const columns: TableColumnsType = [
    {
      title: '№',
      dataIndex: 'id',
      width: 20,
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('id'),
      render: (id) => <a onClick={()=>handlerClickId(id)}>{searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={id.toString()}
        />
      ) : (
        id
      )}</a>
    },
    {
      title: 'ТЕГИ',
      dataIndex: 'tags',
      align: 'center',
      width: 35,
      render:(tags: any[]) => (
        <>
          <Icons.TagsFill />
          {/* {tags.map((tag, ind) => {
            const { name } = tag
            let color = '#CD6EE5'
            return (
              <Tooltip
                placement="topRight"
                title={name}
                key={ind}
              >
                <Badge count={tag.id} showZero style={{ backgroundColor: color }} />

              </Tooltip>
              // <Tag color={color} key={ind}>
              //   {name.toUpperCase()}
              // </Tag>
            );
          })} */}
        </>
      )    
    },
    {
      title: 'СТАТУС',
      dataIndex: 'isActive',
      align: 'center',
      width: 35,
      render: (act) => (act)
        ? <Icons.CircleCheck />
        : <Icons.CircleQuestion />
    },
    {
      title: 'СОЗДАНА',
      dataIndex: 'createdAt',
      width: 80,
      render: ((record) => {
        return <span>{record ? moment.utc(record).format('DD.MM.YYYY') : '-'}</span>
      }),
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      width: 140,
      sorter: (a, b) => a.fio && b.fio ? a.fio.localeCompare(b.fio) : 0,
      ...getColumnSearchProps('fio'),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: 110,
      sorter: (a, b) => a.email && b.email ? a.email.localeCompare(b.email) : 0,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'ПОЛ',
      dataIndex: 'sex',
      // align: 'center',
      width: 24,
      filters: [
        {
          text: 'муж',
          value: 1,
        },
        {
          text: 'жен',
          value: 2,
        },
      ],
      onFilter: (value, record) => record.sex === value,
      render: (sex => {
        return (sex === 1)
          ? <ManOutlined style={{ color: "#52c41a" }} />
          : <WomanOutlined style={{ color: "#eb2f96"}}/>
      })
    },
    {
      title: 'ТЕЛЕФОН',
      dataIndex: 'phone',
      width: 110,
      sorter: (a, b) => a.phone - b.phone,
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'МАКЕТ',
      width: 220,
      dataIndex: 'templates',
      render:(templates: any[]) => (
        <>
          {templates.map((template, ind) => {
            const displayName = typeof template.displayName === 'string' ? template.displayName : '';
            let color = (displayName && displayName.length > 10)
                ? (displayName && displayName.length > 13)
                  ? 'volcano'
                  : 'cyan'
                : (displayName && displayName.length < 7)
                  ? 'green'
                : 'blue'
            return (
              <Tag color={color} key={ind}>
                {displayName && displayName.toUpperCase()}
              </Tag>
            );
          })}
        </>
      )    
    },
    {
      title: 'ДАТА РОЖ..',
      width: 85,
      dataIndex: 'birthdate',
      sorter: (a, b) => a.birthdate && b.birthdate ? a.birthdate.localeCompare(b.birthdate) : 0,
      ...getColumnSearchProps('birthdate'),
    },
    {
      title: 'БОНУСЫ',
      dataIndex: 'bonus',
      width: 90,
      // align: 'right',
      // className: 'headerCenter',
      render: (o: any[]) => (
        <>
          {(o)
            ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))}</Sspan>
            : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))}</Nspan>}
        </>
      )
    },
    {
      title: 'БАЛАНС',
      dataIndex: 'balance',
      width: 90,
      // align: 'right',
      // className: 'headerCenter',
      render: (o: any[]) => (
        <>
          {(o)
            ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))}</Sspan>
            : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))}</Nspan>}
        </>
      )
    },
  ];
  
  const renderTableFooter = () => {
    return (
    <div>
      Отфильтровано: {sortedData.length}/{data.length} |
      Выделено: {selectedRowKeys.length}
    </div>
    );
  };
  
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter);
    
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
  };

  const overlayOnClick = () => {
    setOpenedSettingsPopup(false)
  }

  const handlerClickId = (id) => {
    const findClient = data.find(o => o.id === id)
    if (findClient) {
      setSelectedClient(findClient)
      setOpenedSettingsPopup(true)
    }
    
  }
  
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>Клиенты</HeaderTitle>
      </Header>
      <HeaderInfoWrap>
        <CardInfo>
          <CardTextArea>
            <CardTitle>За сегодня</CardTitle>
            <CardText>период статистики</CardText>

          </CardTextArea>
          <CardDesc></CardDesc>
        </CardInfo>
        <Br />
        <CardInfo>
          <CardTextArea>
            <CardTitle>1</CardTitle>
            <CardText>регистраций</CardText>

          </CardTextArea>
        </CardInfo>
        <Br />
        <CardInfo>
          <CardTextArea>
            <CardTitle>100</CardTitle>
            <CardText>сумма депозитов</CardText>

          </CardTextArea>
        </CardInfo>
        <Br />
        <CardInfo>
          <CardTextArea>
            <CardTitle>0</CardTitle>
            <CardText>сумма бонусов</CardText>

          </CardTextArea>
        </CardInfo>
        <Br />
        <CardInfo>
          <CardTextArea>
            <CardTitle>0</CardTitle>
            <CardText>сумма скидок</CardText>

          </CardTextArea>
        </CardInfo>
      </HeaderInfoWrap>
      <SearchWrap>
        <SearchInput
          placeholder='Поиск...'
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <ButtonBlok>
          <StyledButton><Icons.IconSearchVect1 /></StyledButton>
          <StyledButton><Icons.IconSearchVect2 /></StyledButton>
          <StyledButton><Icons.IconSearchVect3 /></StyledButton>
          <StyledButton><Icons.IconSearchVect4 /></StyledButton>
        </ButtonBlok>
      </SearchWrap>
      <TableWrapper>
        <Table
          size='small'
          rowSelection={rowSelection}
          columns={columns}
          rowKey={(rec) => rec.id}
          dataSource={sortedData}
          onChange={handleTableChange}
          pagination={{ pageSize: 10 }}
          footer={renderTableFooter}
        />

      </TableWrapper>
      <Overlay
        onClick={overlayOnClick}
        display={(openedSettingsPopup) ? 'block' : 'none'}
      />
      {openedSettingsPopup && (
        <ClientModal selectedClient={selectedClient} setOpeningSettingsPopup={setOpenedSettingsPopup} />
      )}
    </Wrapper>
   
  )
}

export default ClientsPage;

const Sspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const Nspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`
const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const SearchInput = styled.input`
  width: 1135px;
  padding-left: 20px;
  /* Frame 91 */

  height: 33px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  border: none;

`
const ButtonBlok = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 160px;
`
const StyledButton = styled.div`
  width: 33px;
  height: 33px;
  background: #CD6EE5;
  box-sizing: border-box;
  border: 0.6875px solid #CD6EE5;
  box-shadow: -1.375px 1.375px 4.2625px 1.375px rgba(0, 0, 0, 0.24);
  border-radius: 2.75px;
  padding: 5px;
  cursor: pointer;

`

const TableWrapper = styled.div`
  margin-top: 10px;
  .ant-table-thead {
    height: 50px; /* Вы можете установить желаемую высоту */
    line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
    /* Rectangle 91 */

    background: rgba(148, 129, 205, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0px 0px;
  }
  .ant-table-thead>tr>th::before {
    content: none !important;
  }
  .ant-table-cell {
    border-right: none !important; 
    border-left: none !important;/* Убираем вертикальные границы между ячейками */
  }

  .ant-table-row .ant-table-cell {
    height: 46px !important; 
    /* 79991233232 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & svg {
      margin-top: 9px;
    }
  }

  & td.leftAlign {
    text-align: left !important;
    padding-left: 5px !important;
  }

  & th.headerCenter {
    text-align: center !important;
  }
`

const HeaderInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  /* gap: 60px; */
`

const Br = styled.div`
  width: 0px;
  height: 76px;
  border: 1px solid rgba(143, 143, 143, 0.3);
  
`

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 170px;
  height: 63px;
`

const CardTextArea = styled.div`
  display: flex;
  flex-direction: column;
`

const CardTitle = styled.div`
  /* За сегодня */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`

const CardText = styled.div`
  width: 132px;
  height: 28px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  color: #8F8F8F;
`

const CardDesc = styled.div`
  top: 16px;
  right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
/* identical to box height, or 200% */
  align-items: center;
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`

const HeaderTitle = styled.div`
    /* Клиенты */
  margin-top: 38px;
  width: 110px;
  height: 28px;
  /* Заголовок */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  min-height: 90%;
  top: 0px;
  gap: 16px;
  background-color: #F7F7F7;
  
  .ant-table-row {
    height: 14px !important; 
    padding: 2px !important;/* Установите желаемую высоту строки */
  }

  .ant-table-cell {
    height: 14px !important; 
    padding: 2px !important;/* Установите желаемую высоту строки */
  }

  .ant-table-tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    padding: 2px;
    height: 12px;

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 13.2px;
    text-align: left;

  }
`

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  margin-bottom: 20px;
  /* gap: 30px; */
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const Overlay = styled.div<{
  display: string
}>`
  display: ${({ display }) => display};
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1000;
  width: 100vw;
  height: 150vh;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  transition: width .3s;

`
