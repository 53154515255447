import styled from 'styled-components'
import { Tag } from 'antd';

const menuPageColumns = (nameInventedArray, nameCategoriesArray) => {
  return [
    {
      title: 'CAT',
      dataIndex: 'CATCODE',
      render: ((shops_counter: number) => {
        return <span>{shops_counter}</span>
      }),
      width: 35,
      sorter: (a, b) => a.CATCODEd && b.CATCODE ? a.CATCODE.localeCompare(b.CATCODE) : 0,
    },
    {
      title: 'Локальные категории',
      dataIndex: 'name_invented',
      key: 'name_invented',
      render: (tag: string) => (
        <span>
          <Tag
            color={
              (tag && tag.length > 10)
                ? (tag && tag.length > 15)
                  ? 'volcano'
                  : 'cyan'
                : (tag && tag.length < 7)
                  ? 'green'
                  : 'blue'
            }
            key={tag}
          >
            {tag && tag.toUpperCase()}
          </Tag>
          
        </span>
      ),
      filters: nameInventedArray,
      onFilter: (value, record) => record.name_invented.indexOf(value as string) === 0,
      width: 100,
    },
    {
      title: 'Категория',
      dataIndex: 'CATEGORY',
      key: 'CATEGORY',
      render: (tag: string) => (
        <span>
          <Tag
            color={
              (tag && tag.length > 10)
                ? (tag && tag.length > 15)
                  ? 'volcano'
                  : 'cyan'
                : (tag && tag.length < 7)
                  ? 'green'
                  : 'blue'
            }
            key={tag}
          >
            {tag && tag.toUpperCase()}
          </Tag>
          
        </span>
      ),
      filters: nameCategoriesArray,
      onFilter: (value, record) => record.CATEGORY.indexOf(value as string) === 0,
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'NAME',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
      sorter: (a, b) => a.mUnitsName && b.mUnitsName ? a.mUnitsName.localeCompare(b.mUnitsName) : 0,
    },
    {
      title: 'Цена',
      dataIndex: 'PRICE',
      render: ((value: number) => {
        return <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum>
      }),
      width: 100,
      sorter: (a, b) => a.mUnitsName && b.mUnitsName ? a.mUnitsName.localeCompare(b.mUnitsName) : 0,
    
    },
    {
      title: 'Валюта',
      dataIndex: 'CURRENCY',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 30,
    },
  ]
}

const columns1Main = [
  {
    title: 'CLOSESTATION',
    dataIndex: 'closeStation',
    width: 150,
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalAmount',
    width: 30,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum>,
  },
]

const columns1Nested = [
  {
    title: 'SHIFTDATE',
    dataIndex: 'shiftDate',
    width: 30,
  },
  {
    title: 'shiftDateAmount',
    dataIndex: 'shiftDateAmount',
    width: 30,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum>,
  },  
]

const columns1SubNested = [
    {
      title: 'currencyType',
      dataIndex: 'currencyType',
      width: 150,
    },
    {
      title: 'currencyTypeAmount',
      dataIndex: 'currencyTypeAmount',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
    },
    
]
  
const columns1Details = [
  {
      title: 'CURRENCY',
      dataIndex: 'currency',
      width: 150,
  },
  {
      title: 'originalSum',
      dataIndex: 'originalSum',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
    
]

const columnsPivotTable = [
  {
      title: 'Closestation',
      dataIndex: 'station',
      width: 150,
  },
  {
      title: 'Кредитные карты',
      dataIndex: 'amount1',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Наличность',
      dataIndex: 'amount2',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Платежные карты',
      dataIndex: 'amount3',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Общая выручка',
      dataIndex: 'amount',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Чистая выручка',
      dataIndex: 'amount5',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
    
]

const columnsPivotWaiter = [
  {
      title: 'Официант',
      dataIndex: 'waiter',
      width: 150,
  },
  {
      title: 'Кредитные карты',
      dataIndex: 'amount1',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Наличность',
      dataIndex: 'amount2',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Платежные карты',
      dataIndex: 'amount3',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Общая выручка',
      dataIndex: 'amount',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
      title: 'Чистая выручка',
      dataIndex: 'amount5',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
    
]

const columnsDynamicPage = [
  {
    title: 'Период',
    dataIndex: 'period',
    width: 150,
  },
  {
    title: 'Платежные карты',
    dataIndex: 'cards',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
    title: 'Наличность',
    dataIndex: 'cash',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
    title: 'Кредитные карты',
    dataIndex: 'credit',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
    title: 'Открытые столы',
    dataIndex: 'tables',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
    title: 'Общая выручка',
    dataIndex: 'revenue',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum> ,
  },
  {
    title: 'Чистая выручка',
    dataIndex: 'net',
    width: 90,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} р.</StyledSum>,
  }
]
export {
  menuPageColumns,
  columns1Main,
  columns1Nested,
  columns1SubNested,
  columns1Details,
  columnsPivotTable,
  columnsPivotWaiter,
  columnsDynamicPage,
}
  
const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
