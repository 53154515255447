import Wallet from './wallet_fill.svg';
import AccountIcon from './ic_baseline-account-circle.svg';
import MenuDotFill from './menu_dot_fill.svg';
import { ReactComponent as IconHeaderRkeeper } from './i_rkeeper.svg';
import { ReactComponent as IconHeaderBooking } from './i_booking.svg';
import { ReactComponent as IconHeaderCrm } from './i_crm.svg';
import { ReactComponent as IconHeaderVideo } from './i_video.svg';
import { ReactComponent as IconHeaderSetting } from './i_setting.svg';
import { ReactComponent as IconPieChart } from './pie_chart_2_fill.svg';
import { ReactComponent as IconBarChart } from './bar_chart_fill.svg';
import { ReactComponent as IconCalendarClockFill } from './calendar_clock_fill.svg';
import { ReactComponent as IconLineChartFill } from './line_chart_fill.svg';
import { ReactComponent as IconSlideTrendFill } from './slide_trend_fill.svg';
import { ReactComponent as IconSettings } from './gear_2_fill.svg';
import { ReactComponent as IconPlusCircleFill } from './add_circle_fill.svg'
import { ReactComponent as IconMinusCircleFill } from './subtract_circle_fill.svg'
import { ReactComponent as IconMainCalendar } from './calendar_2_fill.svg'
import { ReactComponent as IconHumanAddFill } from './human_add_fill.svg'
import { ReactComponent as IconCloud } from './CloudWSArrow.svg'
import { ReactComponent as IconSearchVect1 } from './vect1.svg'
import { ReactComponent as IconSearchVect2 } from './vect2.svg'
import { ReactComponent as IconSearchVect3 } from './vect3.svg'
import { ReactComponent as IconSearchVect4 } from './vect4.svg'
import { ReactComponent as IconArrowUp } from './arrow_up.svg'
import { ReactComponent as IconArrowDown } from './arrow-down.svg'
import { ReactComponent as CircleCheck } from './CircleCheck.svg'
import { ReactComponent as CircleQuestion } from './CircleQuestion.svg'
import { ReactComponent as DownloadFill } from './iconDownload.svg'
import { ReactComponent as MailFill } from './iconMail.svg'
import { ReactComponent as TagsFill } from './iconTags.svg'
import { ReactComponent as ChartExample } from './ImageChart.svg'
import { ReactComponent as RedTriangle } from './RedTriangle.svg'
import { ReactComponent as Settings3dots } from './Settings3dots.svg'

export const Icons = {
  HeaderIcons: {
    IconHeaderRkeeper,
    IconHeaderBooking,
    IconHeaderCrm,
    IconHeaderVideo,
    IconHeaderSetting,
  },
  IconHumanAddFill,
  IconPieChart,
  IconBarChart,
  IconCalendarClockFill,
  IconMainCalendar,
  IconLineChartFill,
  IconSlideTrendFill,
  IconSettings,
  Wallet,
  AccountIcon,
  MenuDotFill,
  IconPlusCircleFill,
  IconMinusCircleFill,
  IconCloud,
  IconSearchVect1,
  IconSearchVect2,
  IconSearchVect3,
  IconSearchVect4,
  IconArrowUp,
  IconArrowDown,
  CircleCheck,
  CircleQuestion,
  MailFill,
  DownloadFill,
  TagsFill,
  Settings3dots,
  RedTriangle,
  ChartExample,
};
