import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import type { InputRef } from 'antd';

const mockData = [
  {date: '11.06.2024', status: 'Клиент отправлен в ERP', title: 'Загрузка карты в ERP', origin: 'Изменения вручную', description: ''},
  {date: '11.06.2024', status: 'Women 3 коктейля → STAFF электронная ', title: 'Макет', origin: 'Изменения вручную', description: ''},
  {date: '11.06.2024', status: 'Клиент отправлен в ERP', title: 'Загрузка карты в ERP', origin: 'Изменения вручную', description: ''},
]

interface PropsClientModal {
  selectedClient: any
  offset?: any;
  keys?: number;
  setOpeningSettingsPopup: any;
}

moment().format();

const ContentModalHistory: React.FC<PropsClientModal> = ({selectedClient, keys, setOpeningSettingsPopup }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [dataHistory, setDataHistory] = useState<any[]>([])

  const inputRef = useRef<InputRef>(null);
  
  useEffect(() => {
    if (loading ) {
      setLoading(false);
      const clientsUrl = `https://stage.proga.pro/api/shops/1/loyalty/clients/${selectedClient.id}/cards/history`
      Promise.all([
        fetch(clientsUrl, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          if (data && Array.isArray(data) && data.length > 0) {
           setDataHistory(data) 
          } else setDataHistory([])

        })
      setLoading(false);
    }
  }, [loading])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    // if (inputValue && !tags.includes(inputValue)) {
      // setTags([...tags, inputValue]);
    // }
    setInputVisible(false);
    setInputValue('');
  };

  const showInput = () => {
    setInputVisible(true);
  };

  return (
    <Wrapper>
      <Hr />
      <WrapHistory>
        {mockData.map((item, ind) => (
          <Card key={ind}>
            <StyledDate>{item.date}</StyledDate>
            <StyledLine></StyledLine>
            <InfoWrap>
              <StyledImage></StyledImage>
              <StyledFrameInfo>
                <StyledTextArea><span>{item.title}</span></StyledTextArea>
                <StyledTextArea>Статус: <span>{item.status}</span></StyledTextArea>
                <StyledTextArea>Источник изменений: <span>{item.origin}</span></StyledTextArea>
                <StyledTextArea style={{marginTop: '15px'}}>Комментарий: <span>{item.description}</span></StyledTextArea>
                <StyledAddDescription>Добавить комментарий</StyledAddDescription>
              </StyledFrameInfo>
            </InfoWrap>
          </Card>
        ))}
      </WrapHistory>
      <Hr />

      <StyledFooter>
        <StyledButtonClose onClick={() => setOpeningSettingsPopup(false)}>Закрыть</StyledButtonClose>
        <StyledButtonSave onClick={() => setOpeningSettingsPopup(false)}><span>Сохранить</span></StyledButtonSave>
      </StyledFooter>
    </Wrapper>
   
  )
}

export default ContentModalHistory;

const WrapHistory = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 680px;
  height: 420px;
  gap: 10px;
  overflow-y: auto; /* Добавлено для прокрутки */
  
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Стилизация ползунка скроллбара */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 50%;
  }

  /* Стилизация ползунка при наведении */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  /* Стили для Firefox */
  scrollbar-color: darkgrey #ffffff;
  scrollbar-width: thin;
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 610px;
  height: 182px;
`

const StyledDate = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

`

const StyledLine = styled.div`
  width: 0px;
  height: 182px;
  margin-left: 10px;
  border: 2px solid #333333;
`

const InfoWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 25px 15px 23px;
  gap: 32px;
  margin-top: 11.5px;
  margin-left: 10px;
  
  width: 560px;
  height: 159px;

  /* #D6D6D6 графики */
  border: 1px solid #D6D6D6;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

`

const StyledImage = styled.div`
  
`

const StyledFrameInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTextArea = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  color: #8F8F8F;

  span {
    margin-left: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: #333333;
  }
`

const StyledAddDescription = styled.div`
  margin-top: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #8F8F8F;
  cursor: pointer;
  opacity: 0.5;

`

const StyledButtonClose = styled.div`
  /* Закрыть */

  width: 59px;
  height: 28px;

  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
  cursor: pointer;

`

const StyledButtonSave = styled.div`
    /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 58px;
  gap: 10px;
  cursor: pointer;
  width: 195px;
  height: 40px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  & span {
        /* Сохранить */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;
    text-align: center;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    color: #FFFFFF;
  }
`

const StyledFooter = styled.div`
  /* Frame 40 */
  display: flex;
  gap: 34px;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  margin-top: 11px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const Hr = styled.hr`
  /* Разделительная */
  width: 680px;
  height: 0px;
  border: 3px solid rgba(143, 143, 143, 0.3);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Br = styled.hr`
  /* Vector 11 */
  margin: 5px 0px;
  width: 600px;
  height: 0px;

  border: 1px solid rgba(143, 143, 143, 0.3);
  
`

const Wrapper = styled.div`
  /* Frame 85 */
  display: flex;
  flex-direction: column;
  width: 680px;
  height: 524px;
  z-index: 1205;
  
`
