import React from 'react';
import Layout from '../../components/Layout';

interface PropsBooking {
    sidemenu: any;
}

const  Booking = ({sidemenu}: PropsBooking) =>{
    return (
        <Layout sidemenu={sidemenu}>
            <div className='Booking'>
                <h1>Welcome to the main Booking - booking</h1>
            </div>

        </Layout>
    )
}

export default Booking;