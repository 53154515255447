import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import type { InputRef } from 'antd';
import dayjs from 'dayjs'
import { Modal } from 'antd/lib';
import ContentModalCheck from './ContentModalCheck';

interface PropsClientModal {
  selectedClient: any
  offset?: any;
  keys?: number;
  setOpeningSettingsPopup: any;
  selectedCards: any[];
}

moment().format();

const formaterSum = (value) => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value))

const ContentModalBayes: React.FC<PropsClientModal> = ({ selectedClient, keys, setOpeningSettingsPopup, selectedCards }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataHistoryBayes, setDataHistoryBayes] = useState<any[]>([])
  const inputRef = useRef<InputRef>(null);
  const [selectedCheck, setSelectedCheck] = useState(null);
  
  useEffect(() => {
    if (loading) {
      setLoading(false);
      const clientsUrl = `https://stage.proga.pro/api/shops/1/loyalty/clients/${selectedClient.id}/cards/history`
      Promise.all([
        fetch(clientsUrl, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          if (data && Array.isArray(data) && data.length > 0) {
            const datas = data.filter(i => selectedCards.includes(i.card_code))
            
            setDataHistoryBayes(datas) 
          } else setDataHistoryBayes([])

        })
      setLoading(false);
    }
  }, [loading, selectedClient, selectedCards])

  useEffect(() => {
    console.log(selectedCards);
    
    setLoading(true)
  }, [selectedCards])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handlerCheck = (item) => {
    setModalOpen(true)
    setSelectedCheck(item)
  }

  return (
    <Wrapper>
      <StyledTableWrapper>
        <StyledTableHeader>
          <HeaderTitle>Карточка</HeaderTitle>
          <HeaderTitle>Дата</HeaderTitle>
          <HeaderTitle>Чек</HeaderTitle>
          <HeaderTitle>Сумма <span>со скидкой</span></HeaderTitle>
          <HeaderTitle>Бонусы</HeaderTitle>
          <HeaderTitle>Баланс</HeaderTitle>
        </StyledTableHeader>
        <StyledTableBody>
          <Br />
          {dataHistoryBayes.map((item, ind) => (
            <React.Fragment key={`fragment-${ind}`}>
            <InfoWrap key={`infowrap-${ind}`}>
              <InfoOrg key={`infocard-${ind}`}>{item.card_code}</InfoOrg>
              <InfoDate key={`infodate-${ind}`}>
                <span>{dayjs(item.date).format('DD.MM.YYYY')}</span>
                <span>{dayjs(item.date).format('HH:mm:ss')}</span>
              </InfoDate>
              <InfoCheck
                key={`infocheck-${ind}`}
                onClick={()=>handlerCheck(item)}
              >{item.checknum}</InfoCheck>
              <InfoSumm key={`infosum-${ind}`}>{(item.discount)? formaterSum(item.discount): '0,00'}</InfoSumm>
               
              {(item.bonus)
                ? (item.bonus > 0)
                    ? <InfoSummP key={`infobonusp-${ind}`}>{formaterSum(item.bonus)}</InfoSummP>
                    : (item.bonus < 0)
                      ? <InfoSummM key={`infobonusm-${ind}`}>{formaterSum(item.bonus)}</InfoSummM>
                      : <InfoSumm key={`infobonus-${ind}`}>{'0,00'}</InfoSumm>
                : <InfoSumm key={`infobonuse-${ind}`}>{'0,00'}</InfoSumm>
              } 
              {(item.balance)
                ? (item.balance > 0)
                    ? <InfoSummP key={`infobalancep-${ind}`}>{formaterSum(item.balance)}</InfoSummP>
                    : (item.balance < 0)
                      ? <InfoSummM key={`infobalancesm-${ind}`}>{formaterSum(item.balance)}</InfoSummM>
                      : <InfoSumm key={`infobalance-${ind}`}>{'0,00'}</InfoSumm>
                : <InfoSumm key={`infobalance-${ind}`}>{'0,00'}</InfoSumm>
              } 
            </InfoWrap>
            <Br />
            </React.Fragment>
          ))}
          <StyledModal
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            zIndex={3300}
            width={1200}
          >
            <ContentModalCheck
              selectedCheck={selectedCheck}
              selectedClient={selectedClient}
            />
          </StyledModal>
        </StyledTableBody>
      </StyledTableWrapper>
      <StyledFooter>
        <StyledButtonClose onClick={() => setOpeningSettingsPopup(false)}>Закрыть</StyledButtonClose>
        <StyledButtonSave onClick={() => setOpeningSettingsPopup(false)}><span>Сохранить</span></StyledButtonSave>
      </StyledFooter>
    </Wrapper>
   
  )
}

export default ContentModalBayes;

const StyledModal = styled(Modal)`
  z-index: 1500;

`

const StyledTableWrapper = styled.div`
  height: 460px;
`

const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row nowrap;
  width: 100%;
  height: 56px;
  gap: 4px;
  padding: 10px;
`

const HeaderTitle = styled.div`
    /* Организация */

  width: 105px;
  height: 23px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

  & span {
    position: absolute;
    top: 28px;
    /* со скидкой */

    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height, or 107% */
    color: #8F8F8F;
  }
`

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Стилизация ползунка скроллбара */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 50%;
  }

  /* Стилизация ползунка при наведении */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  /* Стили для Firefox */
  scrollbar-color: darkgrey #ffffff;
  scrollbar-width: thin;
`

const InfoOrg = styled.div`
  /* Микс Afterparty (MIDSRV) */

  width: 105px;
  height: 30px;
  left: 13.25px;
  top: 7px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
`

const InfoCheck = styled.div`
    /* Ручное списание бонусов */

  width: 105px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: center;

  /* Текст/#A7388E текст|кнопки */
  color: #A7388E;
  cursor: pointer;

`

const InfoSumm = styled.div`
  /* 0,00 */

  width: 105px;
  height: 15px;
  left: 238.25px;
  top: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`

const InfoSummP = styled.div`
  /* 0,00 */

  width: 105px;
  height: 15px;
  left: 238.25px;
  top: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: start;

  /* Текст/#8F8F8F текст */
  color: #3BCA1E;


`

const InfoSummM = styled.div`
  /* 0,00 */

  width: 105px;
  height: 15px;
  left: 238.25px;
  top: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: start;

  /* Текст/#8F8F8F текст */
  color: #CA281E;


`

const InfoDate = styled.div`
  display: flex;
  flex-direction: column;
  width: 105px;
  height: 30px;
  left: 332.25px;
  top: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: start;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`

const StyledButtonClose = styled.div`
  /* Закрыть */

  width: 59px;
  height: 28px;

  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
  cursor: pointer;

`

const StyledButtonSave = styled.div`
    /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 58px;
  gap: 10px;
  cursor: pointer;
  width: 195px;
  height: 40px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  & span {
        /* Сохранить */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;
    text-align: center;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    color: #FFFFFF;
  }
`

const StyledFooter = styled.div`
  /* Frame 40 */
  display: flex;
  gap: 34px;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  margin-top: 16px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const Hr = styled.hr`
  /* Разделительная */
  width: 600px;
  height: 0px;
  margin-bottom: 15px;
  border: 3px solid rgba(143, 143, 143, 0.3);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);

`;

const Br = styled.hr`
  /* Vector 11 */
  margin: 5px 0px;
  width: 650px;
  height: 0px;

  border: 1px solid rgba(143, 143, 143, 0.3);
  
`

const Wrapper = styled.div`
  /* Frame 85 */
  display: flex;
  flex-direction: column;
  width: 680px;
  height: 520px;
  z-index: 1200;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
  
`

