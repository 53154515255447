import React from 'react';
import Layout from '../../components/Layout';

interface PropsCrmPage {
    sidemenu: any;
}

const  CrmPage = ({sidemenu}: PropsCrmPage) =>{
    return (
        <Layout sidemenu={sidemenu}>
            <div>
            </div>

        </Layout>
    )
}

export default CrmPage;