import {CSSProperties, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components'
import { TableWrapper } from '../../components/TableWrapper';
import { PuffLoader } from 'react-spinners';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined } from '@ant-design/icons';
import { getNoun } from '../../utils/getNoun';
import dayjs from 'dayjs';
import moment from 'moment';
import { useApplication } from '../../context/ApplicationContext';
import _ from 'lodash'
import { CSVLink } from 'react-csv';

import { Icons } from '../../assets';
import { Tag, DatePicker } from 'antd'
const { RangePicker } = DatePicker;

const defaultInventCategory = { 
  parent_id: 0, 
  shop_id: 1, 
  color: '#000000', 
  borderColor: '#000000',
  name_invented: 'Прочее',
}

const tagColors = [
  'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple', 'magenta', 'red', 'volcano', 'orange', 
  'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple', 'magenta', 'red', 'volcano', 'orange', 
  'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple', 'magenta', 'red', 'volcano', 'orange', 
  '#FBB500', '#F56700', '#FB0069', '#2B1ECA', '#6B00F3', '#BF6DFF', '#0070F3', '#14D6BF', '#3BCA1E', '#A1F01F', '#CA281E', '#DD1A6A',
  '#FBB500', '#F56700', '#FB0069', '#2B1ECA', '#6B00F3', '#BF6DFF', '#0070F3', '#14D6BF', '#3BCA1E', '#A1F01F', '#CA281E', '#DD1A6A'
]
const ToppositionsPage = () => {
  const { inventGroups, setInventGroups, dateRange, setDateRange } = useApplication()
  const [filteredData, setFilteredData] = useState<any>([]);
  const [goodsData, setGoodsData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<string | number>('2024-05-09')
  const [toDate, setToDate] = useState<string | number>('2024-05-16')
  const [nameInventedArray, setNameInventedArray] = useState<any[]>([]);
  const [nameCategoriesArray, setNameCategoriesArray] = useState<any[]>([]);

  useEffect(() => {
    const from = dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf().toString()
    const to = dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf().toString()
    const url = `https://stage.proga.pro/api/shops/1/menu/top?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
    
    Promise.all([
      fetch(`https://stage.proga.pro/api/shops/1/inventedCategory/items`, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(res => res.json())
        .catch(() => []),
      fetch(url, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(res => res.json())
        .catch(() => []),
    ])
      .then(([inventedGroupsData, data]) => {
        if (data && Array.isArray(data) && inventedGroupsData && data.length > 0) {
          const updatedData = data.map((item) => {
            const { CATCODE: catcode } = item
            let indexInvent = _.findIndex(inventedGroupsData, { 'category_id': catcode})
            if (indexInvent !== -1) {
              return { ...item, ...inventedGroupsData[indexInvent].invent }
            } else {
              
              return { ...item, ...defaultInventCategory }

            }
            
          })
          const inventedCategoriesNameArray = _(updatedData)
            .map(i => i.name_invented)
            .uniq()
            .map(o => ({
              text: o,
              value: o,
            }))
            .value()
          
          const categoriesNameArray = _(updatedData)
            .map(i => i.CATEGORY)
            .uniq()
            .map(o => ({
              text: o,
              value: o,
            }))
            .value()
            
          setNameInventedArray(inventedCategoriesNameArray)
          setNameCategoriesArray(categoriesNameArray)
          setGoodsData(updatedData)
          setFromDate(from)
          setToDate(to)

            
        }
      })
    
  }, [dateRange])

  useEffect(() => {
    if (goodsData) setFilteredData(goodsData)
  }, [goodsData])

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
    } else {
      // setHistoryDateRange([])
    }
  }
  
  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const columns: ColumnsType = [
    // {
    //   title: '№CAT',
    //   dataIndex: 'CATCODE',
    //   key: 'CATCODE',
    //   width: 50,
    // },
    {
      title: 'Локальные категории',
      dataIndex: 'name_invented',
      key: 'name_invented',
      render: (tag: string) => (
        <span>
          <Tag
            color={
              (tag && tag.length > 0)
                ? (tag.length > 24)
                  ? 'red'
                  : tagColors[tag.length]
                : 'blue'
            }
            key={tag}
          >
            {tag && tag.toUpperCase()}
           </Tag>
          
        </span>
      ),
      filters: nameInventedArray,
      onFilter: (value, record) => {
        if (record.name_invented === value) {
          console.log(value, record);
          return true
        } else {
          return false
        }
      },
      width: 100,
    },
    {
      title: 'Категория',
      dataIndex: 'CATEGORY',
      key: 'CATEGORY',
      render: (tag: string) => (
        <span>
          <Tag
            color={
              (tag && tag.length > 10)
                ? (tag && tag.length > 15)
                  ? 'volcano'
                  : 'cyan'
                : (tag && tag.length < 7)
                  ? 'green'
                  : 'blue'
            }
            key={tag}
          >
            {tag && tag.toUpperCase()}
          </Tag>
          
        </span>
      ),
      filters: nameCategoriesArray,
      onFilter: (value, record) => {
        if (record.CATEGORY === value) {
          console.log(value, record);
          return true
        } else {
          return false
        }
      },
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'NAME',
      key: 'NAME',
      render: ((name: string) => {
        return <span>{name}</span>
      }),
      width: 100,
      // sorter: (a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0,
    },
    {
      title: 'Количество',
      dataIndex: 'QUANTITY',
      key: 'QUANTITY',
      render: ((quantity: number) => {
        return <StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(quantity))}</StyledSum>
      }),
      width: 100,
      // sorter: (a, b) => a.mUnitsName && b.mUnitsName ? a.mUnitsName.localeCompare(b.mUnitsName) : 0,
    
    },
  ]

  const columnsCSV = [
    { title: 'Локальные категории', dataIndex: 'name_invented'},
    { title: 'Категория', dataIndex: 'CATEGORY'},
    { title: 'Наименование', dataIndex: 'NAME'},
    { title: 'Количество', dataIndex: 'QUANTITY'},
  ];
  
  return (
    <Wrapper>
      <Header>
        <div style={{display: 'flex', gap: '20px'}}>
          <Title>
            Категории
            <SubTitle>
              {(nameCategoriesArray)
                ? getNoun(nameCategoriesArray.length, 'категория', 'категории', 'категорий')
                : 'нет товаров'}
            </SubTitle>
          </Title>
          <Title>
            Товары
            <SubTitle>
              {(goodsData)
                ? getNoun(goodsData.length, 'товар', 'товара', 'товаров')
                : 'нет товаров'}
            </SubTitle>
          </Title>

        </div>
        {/* <RangePicker
          onChange={onDateChange}
          value={[dayjs(fromDate), dayjs(toDate)]}
          format={['YYYY-MM-DD', 'YYYY-MM-DD']}
          // defaultValue={[dayjs(fromDate, dateFormat), dayjs(toDate, dateFormat)]}
        /> */}
        <StyledCaledarWrapper>
          <CSVLink
            data={filteredData}
            separator={";"}
            headers={columnsCSV.map(col => ({ label: col.title as string, key: col.dataIndex as string }))}
            filename={`toppositions_${moment.utc(fromDate).format('YYYY-MM-DD')}_${moment.utc(toDate).format('YYYY-MM-DD')}.csv`}
          >
            <StyledCalendarMemo>
              <DownloadOutlined style={{ color: 'hotpink' }} />
              Экспортировать в CSV
            </StyledCalendarMemo>

          </CSVLink>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(fromDate), dayjs(toDate)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
          {/* {(chartIsOpen) ?
            (<Button onClick={onButtonClick}>
              <span>+</span>
              переключиться на диаграмму
            </Button>)
          :
            (<Button onClick={onButtonClick}>
              <span>+</span>
              переключиться на график
            </Button>)} */}
        </StyledCaledarWrapper>
        
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <TableWrapper
          data={goodsData}
          loading={loading}
          columns={columns}
          setFilteredData={setFilteredData}
          filteredData={filteredData}  
        />
          
      )}
    </Wrapper>
   
  )
}

export default ToppositionsPage;

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #fff;
  padding: 16px 29px 16px 128px;

  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const SubTitle = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #999999;
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`