import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import InnerContent from "./components/InnerContent"
import ProtectedRoutes from "./components/ProtectedRoutes"
import PublicRoutes from "./components/PublicRoutes"

import LoginPage from "./pages/LoginPage"

import Rkeeper from "./pages/rkeeper/Rkeeper"
import Viruchka from "./pages/rkeeper/ViruchkaPage"
import DynamicPage from "./pages/rkeeper/DynamicPage"
import Ostatki from "./pages/rkeeper/OstatkiPage"
import Toppositions from "./pages/rkeeper/ToppositionsPage"
import Stoppositions from "./pages/rkeeper/StoppositionsPage"
import MenuPage from "./pages/rkeeper/MenuPage"

import Booking from "./pages/booking/Booking"

import CrmPage from "./pages/crm/CrmPage"
import ClientsPage from "./pages/crm/ClientsPage"
import OriginsPage from "./pages/crm/OriginsPage"
import HelpersPage from "./pages/crm/HelpersPage"
import MarketingPage from "./pages/crm/MarketingPage"
import StatisticsPage from "./pages/crm/StatisticsPage"
import ReportsPage from "./pages/crm/ReportsPage"

import VideoPage from "./pages/VideoPage"
import SettingsPage from "./pages/settings/SettingsPage"
import AccountPage from "./pages/settings/AccountPage"
import ShopsPage from "./pages/settings/ShopsPage"

import Tabs from "./components/Tabs"
import Tab1 from "./components/Tab1"
import Tab2 from "./components/Tab2"
import Tab3 from "./components/Tab3"
import Users from "./components/Users"
import NewUser from "./components/NewUser"
import SingleUser from "./components/SingleUser"

import Missing from "./pages/Missing"

import { navigationItems } from './config'

const MainRoutes = () => (
	<Routes>
		{/** Protected Routes */}
		{/** Wrap all Route under ProtectedRoutes element */}
		<Route path="/" element={<ProtectedRoutes />}>
			<Route path="/" element={<InnerContent />}>
				<Route path="/" element={<Navigate replace to="rkeeper" />} />
				<Route path="rkeeper" element={<Rkeeper sidemenu={navigationItems.rkeeper} />}>
					<Route path="/rkeeper" element={<Navigate replace to="viruchka" />} />
					<Route path="viruchka" element={<Viruchka />} />
					<Route path="dynamic" element={<DynamicPage />} />
					<Route path="menu" element={<MenuPage />} />
					<Route path="ostatki" element={<Ostatki />} />
					<Route path="toppositions" element={<Toppositions />} />
					<Route path="stoppositions" element={<Stoppositions />} />
				</Route>

				<Route path="booking" element={<Booking sidemenu={navigationItems.booking} />} />
				
        <Route path="crm" element={<CrmPage sidemenu={navigationItems.crm} />}>
					<Route path="/crm" element={<Navigate replace to="marketing" />} />
					<Route path="clients" element={<ClientsPage />} />
					<Route path="clients_origins" element={<OriginsPage />} />
					<Route path="marketing" element={<MarketingPage />} />
					<Route path="statistics" element={<StatisticsPage />} />
					<Route path="reports" element={<ReportsPage />} />
				</Route>
      
				<Route path="video" element={<VideoPage sidemenu={navigationItems.video} />} />
				<Route path="settings" element={<SettingsPage sidemenu={navigationItems.settings} />} >
					<Route path="/settings" element={<Navigate replace to="account" />} />
					<Route path="account" element={<AccountPage />} />
					<Route path="salepoints" element={<ShopsPage />} />
				</Route>
				<Route path="tabs" element={<Tabs props={{userName: "Leonid"}} />}>
					<Route path="/tabs" element={<Navigate replace to="tab1" />} />
					<Route path="tab1" element={<Tab1 />} />
					<Route path="tab2" element={<ProtectedRoutes roleRequired="USER" />}>
						<Route path="/tabs/tab2" element={<Tab2 />} />
					</Route>
					<Route path="tab3" element={<Tab3 />} />
				</Route>
				{/* <Route path="settings" element={<Settings />} /> */}
				<Route
					path="users"
					element={<Users extraItem="test extra item from router" />}
				/>
				<Route path="users/:userId" element={<SingleUser />} />
                <Route path="users/new" element={<NewUser />} />
                <Route path="*" element={<Missing />} />
			</Route>
		</Route>

		{/** Public Routes */}
		{/** Wrap all Route under PublicRoutes element */}
		<Route path="login" element={<LoginPage />}>
			<Route path="/login" element={<LoginPage />} />
		</Route>

		{/** Permission denied route */}
		<Route path="/denied" element={<Missing />} />
	</Routes>
)

export default MainRoutes