import styled from 'styled-components';

const legendCallback = (chart, toggleDatasetVisibility, hiddenDatasets) => {
  const { data } = chart;
  return (
    <LegendWrap>
      {data.datasets.map((dataset, i) => (
        <LegendItem key={i} onClick={() => toggleDatasetVisibility(i)}>
        <LegendColorBox color={dataset.backgroundColor}>
        {!hiddenDatasets.includes(i) && (
          <CheckIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11" fill="none" stroke="white" >
            <path d="M1.5 3.68778L5.82143 8.61538L12.5 1" stroke-width="2"></path>
          </CheckIcon>
        )}
        </LegendColorBox>
        <LegendLabel>{dataset.label}</LegendLabel>
        </LegendItem>
      ))}
    </LegendWrap>
  );
};


export function LegendContainer({
  datas,
  setHiddenDatasets,
  hiddenDatasets,
}) { 
  
  
  const toggleDatasetVisibility = (index: number) => {
    setHiddenDatasets((prev) => {
      const newHiddenDatasets = [...prev];
      if (newHiddenDatasets.includes(index)) {
        newHiddenDatasets.splice(newHiddenDatasets.indexOf(index), 1);
      } else {
        newHiddenDatasets.push(index);
      }
      return newHiddenDatasets;
    });
  };

  
  return (
    <>
      <div id="legend-container">
      {legendCallback({ data: datas }, toggleDatasetVisibility, hiddenDatasets)}
      </div>
    </>);
}

const LegendWrap = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LegendItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const LegendColorBox = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: ${props => props.color};
  border-radius: 2px;
`;

const LegendLabel = styled.span`
  margin-right: 10px;
  /* Woman card */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #333333;
`;

const CheckIcon = styled.svg`
  width: 16px;
  height: 16px;
  /* fill: white; Цвет галочки */
`;

