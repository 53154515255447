import React from 'react';
import Layout from '../components/Layout';

interface PropsVideoPage {
    sidemenu: any;
}

const  VideoPage = ({sidemenu}: PropsVideoPage) =>{
    return (
        <Layout sidemenu={sidemenu}>
            <div className='VideoPage'>
                <h1>Welcome to the main VideoPage - VideoPage</h1>
            </div>

        </Layout>
    )
}

export default VideoPage;