import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, WalletOutlined, BellFilled, SettingFilled } from '@ant-design/icons';
import { styled } from 'styled-components'
import { Icons } from "../../assets"; 
import useAuth from '../../hooks/useAuth';


const DropdownMenu = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { setAuth }: any = useAuth();
  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      // Перенаправление на страницу логинизации
    setAuth(undefined);
    window.location.href = '/login'
    }
  };

  const menu = (
    <WrapDropdown>
      <WrapUserCard>
        <Icons.IconHumanAddFill />
        <WrapTitle>
          <TitleCard>mixafterparty.com</TitleCard>
          <StyledButton><span>Редактировать профиль</span></StyledButton>
        </WrapTitle>
      </WrapUserCard>
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="addBalance" icon={<WalletOutlined />}>
          Пололнить баланс
        </Menu.Item>
        <Menu.Item key="notification" icon={<BellFilled />}>
          Уведомления
        </Menu.Item>
        <Menu.Item key="help" icon={<UserOutlined />}>
          Помощь менеджера
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="faq" icon={<UserOutlined />}>
          Справка
        </Menu.Item>
        <Menu.Item key="settings" icon={<SettingFilled />}>
          Настройки
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Выйти
        </Menu.Item>
            
      </Menu>
    </WrapDropdown>
  );

  return (
      <Dropdown
          overlay={menu}
          onVisibleChange={(flag) => setVisible(flag)}
          visible={visible}
      >
          <Avatar style={{ cursor: 'pointer' }} icon={<UserOutlined />} />
      </Dropdown>
      );
  };

export default DropdownMenu;

const WrapDropdown = styled.div`
 
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 376px;
  top: 55px;
  left: 1343px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  border: 0.5px 0px 0px 0px;
  opacity: 0px;
  background: #FFFFFF;

  border: 0.5px solid #D6D6D6;
  /* padding: 30px 28px; */
  box-shadow: 0px 2px 15.2px 0px #0000001C;
`

const WrapUserCard = styled.div`
  height: 180px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 30px 28px 0px 28px;

  & svg path {
    fill: #E0E0E0;
    scale: 1.8;
    

  }

  & svg {
    width: 56px;
    height: 56px;
  }
`

const WrapTitle = styled.div`
  display: flex;
  width: 166px;
  flex-direction: column;
  gap: 2px;
`

const TitleCard = styled.div`
  width: 126px;
  height: 28px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
`

const StyledButton = styled.div`
  width: 166px;
  height: 30px;
  gap: 0px;
  border-radius: 5px;
  opacity: 0px;
  padding-top: 2px;
  text-align: center;

  background: #A7388E;

  & span {
   
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }
`