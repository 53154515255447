import { useState } from 'react';
import dayjs from 'dayjs';
import { LegendContainer } from '../../crm/components/LegendContainer';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { callback } from 'chart.js/dist/helpers/helpers.core';

const linesColorArray = [
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(121, 26, 106)', backgroundColor: 'rgba(121, 26, 106, 0.5)' },
  { borderColor: 'rgb(83, 40, 202)', backgroundColor: 'rgba(48, 34, 202, 0.5)' },
  { borderColor: 'rgb(151, 101, 0)', backgroundColor: 'rgba(151, 101, 0, 0.5)' },
  { borderColor: 'rgb(246, 124, 16)', backgroundColor: 'rgba(246, 124, 16, 0.5)' },
  { borderColor: 'rgb(23, 222, 235)', backgroundColor: 'rgba(23, 222, 235, 0.5)' },
  { borderColor: 'rgb(241, 26, 156)', backgroundColor: 'rgba(241, 26, 156, 0.5)' },
  { borderColor: 'rgb(43, 30, 22)', backgroundColor: 'rgba(43, 30, 20, 0.5)' },
  { borderColor: 'rgb(251, 101, 120)', backgroundColor: 'rgba(251, 101, 120, 0.5)' },
  { borderColor: 'rgb(146, 124, 160)', backgroundColor: 'rgba(461, 1942 16, 00.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
]

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return 'Сумма: ' + new Intl.NumberFormat('ru-RU').format(sum);
};

export function LineChart({ dataSource }) {
  const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);
  if (dataSource) {
    const { range, values } = dataSource
    const datas = {
      labels: range,
      datasets: values.map((item, ind) => {
        return {
          label: item.key,
          data: item.values,
          borderColor: linesColorArray[ind].borderColor,
          backgroundColor: linesColorArray[ind].backgroundColor,
          fill: false,
          spanGaps: false,  // Убедитесь, что пропуски не заполняются
          hidden: hiddenDatasets.includes(ind),
        }
      })
    }
    const options = {
      maintainAspectRatio: false, //отключено пропорциональное изменение сторон
      responsive: true,
      tension: 0.4,
      
      interaction: {
            mode: "index" as 'index'
      },
      
      plugins: {
        tooltip: {
          mode: 'index' as 'index', //вот тут прикол в том, что библиотека не распознает простое строковое значение
          intersect: true,
          usePointStyle: true,
          callbacks: {
            footer: footer,
          },
        },
        legend: {
          display: false, // Отключаем стандартную легенду
          // position: 'top' as const,
          // labels: {
          //   usePointStyle: true,
          //   pointStyle: 'rectRounded',

          // }
        },
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      elements: {
        point: {
          hoverRadius: 6,
          backgroundColor: '#ffffff',
          borderWidth: 1,
          hoverBorderWidth: 2,
        }
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            callback: (value, index, values) => dayjs(range[value]).format('YYYY-MM-DD')
          }
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0
        }
      }
    };

    return <>
      <LegendContainer datas={datas} setHiddenDatasets={setHiddenDatasets} hiddenDatasets={hiddenDatasets} />
      <Line options={options} data={datas} />
    </>

  }
  return <></>
}
