import { CSSProperties, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import useFetchData from '../../hooks/useFetchData';
import { DatePicker } from 'antd'
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useApplication } from '../../context/ApplicationContext';

import { Icons } from '../../assets';

import { LineChart } from './components/LineChart';
import { PieChart } from './components/PieChart';
import TableHeaderViruchka from './components/TableHeaderViruchka';
import { cleanTest, calcSortedDataChart, calcViruchka, calcViruchkaByWaiters, calcViruchkaPivot } from './components/consts';
import TableViruchkaMain from './components/TableViruchkaMain';
import TableViruchkaPivot from './components/TableViruchkaPivot';
import TableViruchkaByWaiter from './components/TableViruchkaByWaiter';

const { RangePicker } = DatePicker;

moment().format();

const Viruchka = () => {
  const [ tableCase, setTableCase ] = useState<number>(0);
  const [ valueRadio, setValueRadio ] = useState(true);
  const { dateRange, setDateRange } = useApplication()
  
  const from = useMemo(() => {
    return dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf()
  } , [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  const [dataValues, setDataValues] = useState<any>(undefined);
  const [dataValuesPivot, setDataValuesPivot] = useState<any>(undefined);
  const [dataValuesWaiters, setDataValuesWaiters] = useState<any>(undefined);
  
  const [chartData, setChartData] = useState<any>(undefined);
  const [chartWithoutCards, setChartWithoutCards] = useState<any>(undefined);
  
  const [chartIsOpen, setChartIsOpen] = useState<boolean>(false)
  
  const urlForWaiters = useMemo(() => {
    return `https://stage.proga.pro/api/shops/1/reports/revenue/waiter/days?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
    // return `https://stage.proga.pro/api/shops/1/reports/revenue/waiter/enums?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
  
  }, [from, to]) 

  const {
    data: waitersData,
    setParams: setHeaderParams1,
  } = useFetchData(urlForWaiters,
    [urlForWaiters, from, to]);
  
  const urlForRemains = useMemo(() => {
    return `https://stage.proga.pro/api/shops/1/reports/revenue/station/days?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
  
  }, [from, to]) 
  // const urlForRemainsGrouped = useMemo(() => `https://stage.proga.pro/api/shops/1/reports/revenue/station?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`, [from, to])
  
  const {
    data: viruchkaData,
    loading,
    setParams: setHeaderParams,
  } = useFetchData(urlForRemains,
    [urlForRemains, from, to]);
  
  useEffect(() => {
    if (viruchkaData && Array.isArray(viruchkaData)) { 
      setDataValues(calcViruchka(viruchkaData))
      setDataValuesPivot(calcViruchkaPivot(viruchkaData))
      setChartData(calcSortedDataChart(viruchkaData))
      setChartWithoutCards(calcSortedDataChart(viruchkaData.filter(i=>i.CURRENCYTYPE !== 'Платежные карты')))
    } else {
      setDataValues(undefined)
      setDataValuesPivot(undefined)
      setChartData(undefined)
      setChartWithoutCards(undefined)
    }
    
  }, [viruchkaData])

  useEffect(() => {
    setDataValuesWaiters(calcViruchkaByWaiters(waitersData))
  }, [waitersData])

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onButtonClick = () => {
    setChartIsOpen(!chartIsOpen)
  } 

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  useEffect(() => {
    setHeaderParams([from, to])
    setHeaderParams1([from, to])
  }, [from, to])

  const handleChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };

  return (
      <Wrapper>
        <Header>
        <StyledHeadTitle>Выручка</StyledHeadTitle>
        <Radio.Group value={valueRadio} onChange={handleChange}>
          <Radio.Button value={false}>Общая выручка</Radio.Button>
          <Radio.Button value={true}>Чистая выручка</Radio.Button>
        </Radio.Group>
        <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(from), dayjs(to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
          {/* {(chartIsOpen) ?
            (<Button onClick={onButtonClick}>
              <span>+</span>
              переключиться на диаграмму
            </Button>)
          :
            (<Button onClick={onButtonClick}>
              <span>+</span>
              переключиться на график
            </Button>)} */}
        </StyledCaledarWrapper>
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
            {viruchkaData && Array.isArray(viruchkaData) && (<ChartsWrapper>
              {/* {(chartIsOpen)
              ? (<PieChart dataSource={dataValues} />)
              : (<LineChart dataSource={chartData}/>)} */}
              {(valueRadio)
                ? (<LineChart dataSource={chartWithoutCards} />)
                : (<LineChart dataSource={chartData} />)}
            </ChartsWrapper>)}
          <TableHeaderViruchka
            tableCase={tableCase}
            setTableCase={setTableCase}
            />
            {(tableCase === 0)
              ? (<TableViruchkaPivot
                    dataValues={dataValuesPivot?.resultedData}
                    loading={loading}
                  />)
              : (tableCase === 1)
                ? (<TableViruchkaMain
                  dataValues={dataValues}
                  loading={loading}
                />)
                : (<TableViruchkaByWaiter
                    dataValues={dataValuesWaiters?.resultedData}
                    loading={loading}
                  />)}  
          
        </div>
      )}
    </Wrapper>

   
  )
}

export default Viruchka;

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const Button = styled.button`
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E65E20;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.02em;
  padding: 0 20px;
  cursor: pointer;

  & span {
    margin-right: 8px;
    font-size: 23px;
    line-height: 21px;
    font-weight: 300;
  }
` 

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  margin-bottom: 60px;
  padding: 30px;

`
