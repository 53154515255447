import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components'
import { DatePicker } from 'antd'
import moment from 'moment';
import dayjs from 'dayjs';
import NavbarTabsModal from './components/NavbarTabsModal';
import { useApplication } from '../../context/ApplicationContext';
import { Icons } from '../../assets';
import NewImage from '../../assets/icons/ChartExample.png'
import BarChart from './components/BarChart';
import { LineChart } from './components/LineChart';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'DD.MM.YYYY'

const chartWithoutCards = {
  range : ['27.04', '28.04', '29.04', '30.04', '01.05', '02.05'],
  values: [
    { key: 'Яндекс директ', values: [0, 160, 130, 180, 240, 160, 80, 120] },
    { key: 'Яндекс карты',  values: [650, 300, 400, 250, 70, 250, 460, 430] },
    { key: 'Гугл карты',    values: [200, 120, 120, 100, 40, 90, 120, 90] },
    { key: 'VK Ads',        values: [0, 120, 300, 150, 90, 80, 70, 60] },
    { key: 'Оффлайн реклама', values: [0, 120, 300, 150, 100, 70, 30, 60] },
  ]
}

const Marketing = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { dateRange, setDateRange } = useApplication()

  const from = useMemo(() => dateRange && dateRange.from ? dateRange.from : moment().utc().valueOf(), [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  const onDateChange = (date: any, dateString: [string, string]) => {
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).format(DATE_FORMAT).valueOf(),
        to: dayjs(dateString[1]).format(DATE_FORMAT).valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  return (
    <Wrapper>
      <Header>
        <WrapCalendar>
          <NavbarTabsModal
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <StyledRangePicker
            value={[dayjs(from), dayjs(to)]}
            format={[DATE_FORMAT, DATE_FORMAT]}
            onChange={onDateChange}
          />
        </WrapCalendar>
      </Header>
      <TitleText>Общие показатели</TitleText>
      <WrapperSmallWidgets>
        <WrapWidgetSmall>
          <WSTitleWrap>
            <WSTitle>Регистрации</WSTitle>
            <Icons.Settings3dots />
          </WSTitleWrap>
          <WSInfoWrap>
            <WSValue>3510</WSValue>
            <WSDeltaWrap>
              <Icons.RedTriangle />
              <WSDeltaValue>-10.39%</WSDeltaValue>
              <Icons.ChartExample />
            </WSDeltaWrap>
          </WSInfoWrap>
        </WrapWidgetSmall>
        <WrapWidgetSmall>
          <WSTitleWrap>
            <WSTitle>Сумма депозитов</WSTitle>
            <Icons.Settings3dots />
          </WSTitleWrap>
          <WSInfoWrap>
            <WSValue>3510</WSValue>
            <WSDeltaWrap>
              <Icons.RedTriangle />
              <WSDeltaValue>-10.39%</WSDeltaValue>
              <Icons.ChartExample />
            </WSDeltaWrap>
          </WSInfoWrap>
        </WrapWidgetSmall>
        <WrapWidgetSmall>
          <WSTitleWrap>
            <WSTitle>Сумма бонусов</WSTitle>
            <Icons.Settings3dots />
          </WSTitleWrap>
          <WSInfoWrap>
            <WSValue>3510</WSValue>
            <WSDeltaWrap>
              <Icons.RedTriangle />
              <WSDeltaValue>-10.39%</WSDeltaValue>
              <Icons.ChartExample />
            </WSDeltaWrap>
          </WSInfoWrap>
        </WrapWidgetSmall>
        <WrapWidgetSmall>
          <WSTitleWrap>
            <WSTitle>Сумма скидок</WSTitle>
            <Icons.Settings3dots />
          </WSTitleWrap>
          <WSInfoWrap>
            <WSValue>3510</WSValue>
            <WSDeltaWrap>
              <Icons.RedTriangle />
              <WSDeltaValue>-10.39%</WSDeltaValue>
              <Icons.ChartExample />
            </WSDeltaWrap>
          </WSInfoWrap>
        </WrapWidgetSmall>
        
      </WrapperSmallWidgets>
      <BarChart />
      <TitleText>Источники</TitleText>
      <ChartsWrapper>
        <LineChart dataSource={chartWithoutCards}/>
      </ChartsWrapper>
    </Wrapper>
  )
}

export default Marketing;

const ChartsWrapper = styled.div`
  height: 350px;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 60px;
  padding: 0px;

`

const WSDeltaValue = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #CA281E;
  margin-right: 15px;
`

const WSDeltaWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const WSValue = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #333333;
`

const WSTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
`

const WSTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const WSInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`

const WrapWidgetSmall = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 92px;
  width: 334px;
  background: #FFFFFF;
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: #D6D6D6;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 12px 17px;
`

const WrapperSmallWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 38px 0px;
`

const Wrapper = styled.div`
  min-width: 100%;
  height: calc(100% - 110px);
  background-color: #F7F7F7;
  padding: 0px;
  background: #F7F7F7;

`

const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 81px;
  margin-bottom: 44px;
  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  /* #D6D6D6 графики */
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: #D6D6D6;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 0px 0px 5px 5px;
  padding: 22px 17px 21px 21px;
`

const WrapCalendar = styled.div`
  display: flex;
  flex-direction: row;
  width: 770px;
  height: 38px;
  padding: 5px 15px;
  /* Таблицы/#EEEFEF формы|таблицы */
  background: #EEEFEF;
  border-radius: 5px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  margin-left: 20px;
  /* color: #ffffff; */
  
`

const TitleText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;
  color: #333333;
`