import { CSSProperties, useState, useEffect } from 'react';
import styled from 'styled-components'
import useGetData from '../../hooks/useGetData';
import { getNoun } from '../../utils/getNoun';
import { TableWrapper } from '../../components/TableWrapper';
import { PuffLoader } from 'react-spinners';
import { ColumnsType } from 'antd/lib/table';

const ShopsPage = () => {
  const [shopsData, setShopsData] = useState<any[]>()

  const url = `https://stage.proga.pro/api/shops`;
  const { data, loading } = useGetData({ url });
  // const { user } = data
  
  useEffect(() => {
    if (data) {
      setShopsData(data)
    }
  }, [data])

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const columns: ColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: ((shops_counter: number) => {
        return <span>{shops_counter}</span>
      }),
      width: 20,
      sorter: (a, b) => a.id && b.id ? a.id.localeCompare(b.id) : 0,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
      sorter: (a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
      sorter: (a, b) => a.email && b.email ? a.email.localeCompare(b.email) : 0,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
    },
    {
      title: 'Desc',
      dataIndex: 'desc',
      render: ((shops_counter: string) => {
        return <span>{shops_counter}</span>
      }),
      width: 100,
    },
  ]

  
  return loading ? (
    <PuffLoader color="#E65E20" cssOverride={cssOverride} />
  ) : (
    <Wrapper>
      <Header>
        <Title>
          Точки продаж
          <SubTitle>
              {(shopsData)
                ? getNoun(shopsData.length, 'точка продаж', 'точки продаж', 'точек продаж')
                : 'нет точек продаж'}
          </SubTitle>
        </Title>
        <Button
          onClick={() => { }}
        >
          <span>+</span>
          Добавить точку продаж
        </Button>
      </Header>
      <TableWrapper
        data={shopsData}
          loading={loading}
          columns={columns}
      />
    </Wrapper>
   
  )
}

export default ShopsPage;

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%;
  background-color: #fff;
  padding: 16px 29px 16px 128px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const SubTitle = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #999999;
`

const Button = styled.button`
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E65E20;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.02em;
  padding: 0 20px;
  cursor: pointer;

  & span {
    margin-right: 8px;
    font-size: 23px;
    line-height: 21px;
    font-weight: 300;
  }
` 