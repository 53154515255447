import React, { useState, useEffect } from "react";
import { CookiesProvider, useCookies } from 'react-cookie';
import MainRoutes from "./Routes";
import GlobalStyles from "./GlobalStyles";
import moment, { Moment } from 'moment';
import ApplicationContext, { ApplicationInfo, Theme } from "./context/ApplicationContext";
import SidebarsContext, { useSidebarsContext } from "./context/SidebarsContext";
import { IUser } from "./types";
import _ from 'lodash';
import useFetchData from "./hooks/useFetchData";

interface Props {
  context: ApplicationInfo
}

interface IDateRangeType {
  from: number
  to: number
}

export const getTodayDateRange = () => {
  return {
    from: moment().utc().add(-1, 'month')
      .valueOf(),
    to: moment().utc().valueOf(),
  }
};

const App: React.FC = () => {
  const { pathname, search } = window.location
  const searchParams = new URLSearchParams(search);

  const [theme, setTheme] = useState<Theme>('light');
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [rangeStart, setRangeStart] = useState<string | undefined>(undefined);
  const [rangeEnd, setRangeEnd] = useState<string | undefined>(undefined);
  const [periodDate, setSetPeriodDate] = useState<string | undefined>(undefined);
  const [inventGroups, setInventGroups] = useState<any>(undefined);

  const [dateFilterMain, setDateFilterMain] = useState<string | undefined>(() => {
    if (searchParams.get('date')) {
      return moment(searchParams.get('date')).format('YYYY-MM-DD').valueOf();
    }
    return moment().format('YYYY-MM-DD').valueOf();
  })

  const [dateRange, setDateRange] = useState<IDateRangeType | undefined>(() => {
    const isCurrentDay = moment().isSame(searchParams.get('date'), 'day')
    if (searchParams.get('date') && !isCurrentDay) {
      return {
        from: moment.utc(searchParams.get('date')).startOf('day')
          .valueOf(),
        to: moment.utc(searchParams.get('date')).endOf('day')
          .valueOf(),
      }
    } if (searchParams.get('date') && isCurrentDay) {
      return {
        from: moment.utc(searchParams.get('date')).startOf('day')
          .valueOf(),
        to: moment().utc().add(3, 'hours').valueOf(),
      }
    }
    return getTodayDateRange()
  });

  //! непрвильно - поменять - поэтому и не работало. пропустить через useState()
  const {
    isActiveSubmenu,
    setActiveSubmenu,
    numActiveSubmenu,
    setNumActiveSubmenu,
  } = useSidebarsContext()

  useEffect(() => {
    setInterval(async () => {
      // if (document.visibilityState === 'visible') {
      //   const userBrowserInfo = Bowser.parse(window.navigator.userAgent)
      fetch(`https://stage.proga.pro/api/auth/info`, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(resp => resp.json())
        .then(json => {
          const { token } = json
          const { accessToken } = token
          if (accessToken) localStorage.setItem('accessToken', accessToken)
          // setData(json);
          // setLoading(false);
          // setError(undefined);
        })
        .catch(err => {
          console.log('err', err);
          localStorage.removeItem("user")
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          setUser(undefined);
          removeCookie('id');
          window.location.href = '/login';
        });
      
    }, 30000)
    // }
  
  }, [])

  useEffect(() => {
    
    setUser(user);
  }, [user]);

  const [cookies, setCookies, removeCookie] = useCookies<any['']>(['id']);
  
  return (
    <>
      <CookiesProvider>
        <ApplicationContext.Provider
          value={{
            theme,
            setTheme: updateTheme => setTheme(updateTheme),
            user,
            setUser,
            onLogin: (updatedUser: any) => {
              setUser(updatedUser);
              
              window.location.href = '/';
            },
            onLogout: () => {
              setUser(undefined);
              removeCookie('id');
              window.location.href = '/login';
            },
            rangeStart,
            setRangeStart: (v: string | undefined) => setRangeStart(v),
            rangeEnd,
            setRangeEnd: (v: string | undefined) => setRangeEnd(v),
            periodDate,
            setSetPeriodDate: (v: string | undefined) => setSetPeriodDate(v),
            dateFilterMain,
            setDateFilterMain: (v: string | undefined) => setDateFilterMain(v),
            dateRange,
            setDateRange: (v: any) => setDateRange(v),
            inventGroups,
            setInventGroups: (v: any) => setInventGroups(v),
          }}
        >
          <SidebarsContext.Provider
            value={{
              isActiveSubmenu,
              setActiveSubmenu: (v: boolean) => setActiveSubmenu(v),
              numActiveSubmenu,
              setNumActiveSubmenu: (v: any) => setActiveSubmenu(v),
            }}
          >
            <GlobalStyles/>
            <MainRoutes />

          </SidebarsContext.Provider>

        </ApplicationContext.Provider>
      </CookiesProvider>
    </>
  )
}

export default App;
