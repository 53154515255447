import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components';
import { PuffLoader } from 'react-spinners';

interface Props {
  data: any
  loading: boolean
  columns: any
  setFilteredData?: any
  filteredData?: any
}

export const TableWrapper: React.FC<Props> = ({
  data,
  loading,
  columns,
  setFilteredData,
}) => {
  //код для клика по строке
  const handleRowClick = (record) => {
    // console.log('handleRowClick', record);
  };
  
  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredData(extra.currentDataSource);
  };

  return (data) ? (
    <Wrapper>
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        loading={{ indicator: <PuffLoader color="#E65E20" />, spinning: loading }}
        scroll={{ y: 'calc(100vh - 300px)' }}
        pagination={false}
        // footer={false}
        onChange={handleChange}
        rowKey={(item) => {
          return `${item.ID}-${item.CATCODE}-${item.QUANTITY}`
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record)
        })}
      />
    </Wrapper>
  )
    :
  (<PuffLoader />)
}

const Wrapper = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #1F1E24;
  overflow-y: hidden;
  max-height: calc(100vh - 104px);

    .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    width: 0px;
  }

  .ant-table-thead > tr > th:first-child {
    border-radius: 8px 0 0 8px !important;
  }

  .ant-table-thead > tr > th:last-child {
    border-radius: 0 8px 8px 0 !important;
  }

  .ant-table-column-sorter {
    position: absolute;
    left: 14px;
    border-radius: 4px;
  }

  .ant-table-column-title {
    margin-left: 30px;
  }

  .ant-table-column-sorter-up.active {
    color: #E65E20;
  }
  .ant-table-column-sorter-down.active {
    color: #E65E20;
  }
`