import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import SVGLogo from '../assets/Logo-proga.svg';
import { Logos, Icons } from '../assets';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApplication } from '../context/ApplicationContext';
import { IRespLogin } from '../types';
interface IInitialState {
  login: string;
  password: string;
}

const initialState = {
  login: '',
  password: '',
}

const LoginPage: React.FC = () => { 
  const { setAuth }: any = useAuth();

  const { onLogin } = useApplication();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [state, setState] = useState<IInitialState>(initialState);
  const [loginValue, setLoginValue] = useState<string>('')
  const [passwordValue, setPasswordValue] = useState<string>('')
  const [error, setError] = useState<string>('');
  
  useEffect(() => {
      setError('');
  }, [state])


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    
    if (loginValue && passwordValue) {

    } else {
      setError('Заполните форму')
    }
    
    const value = {
      login: loginValue,
      password: passwordValue
    }
    
    fetch('https://stage.proga.pro/api/auth/login', {
        method: 'POST',
        // credentials: 'include',
        body: JSON.stringify(value),
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(resp => {
        if (resp.status !== 200) {
          setError('Неправильный email и/или пароль!');
          return;
        }
        resp.json()
          .then((data: IRespLogin) => {
            const { user, token } = data
            const {accessToken, refreshToken } = token
            if (!user) {
              setError('Неправильный email и/или пароль!');
              return;
            }
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem("user", JSON.stringify(user))
            setAuth({ user });
            setState(initialState);
            onLogin(user)
            navigate(from, { replace: true });
        })
      })
      .catch(err => {
        console.log(err);
        setError('Неправильный email и/или пароль!');
      })
    
  }
  
  const handleInputLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setLoginValue(value)
  }

  const handleInputPassword= (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setPasswordValue(value)
  }

  return (
    <>
      <GlobalStyle />
      <StyledForm onSubmit={handleSubmit}>
        <StyledLogoHeader>
          <StyledLogo>
            <img src={Logos.LogoBlack} alt="Proga.pro logo" />
          </StyledLogo>
          <StyledHeaderLink>Личный кабинет</StyledHeaderLink>
        </StyledLogoHeader>
        <StyledInputWrapper>
          <StyledLabel>Вход в личный кабинет</StyledLabel>
          <StyledInputContainer>
            <input
              className={error && 'errored'}
              type="text"
              name="login"
              value={loginValue}
              onChange={handleInputLogin}
              required
            />
            <label className={loginValue && 'filled'} htmlFor="login">Логин или email</label>
          </StyledInputContainer>
          <StyledInputContainer>
            <input
              className={error && 'errored'}
              type="password"
              name="password"
              value={passwordValue}
              onChange={handleInputPassword}
              required
              />
              <label className={passwordValue && 'filled'} htmlFor="password">Пароль</label>
                {/* <div onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <Icons.EyeIconOpen />
                    ) : (
                      <Icons.EyeIconClosed />
                    )}
                  </div> */}

          </StyledInputContainer>
          {error && (
            <StyledError>
              <p>{error}</p>
            </StyledError>
          ) }
          <StyledButton type="submit">Войти</StyledButton>
        </StyledInputWrapper>
        <StyledSupplyWrapper>
          <StyledSupplyLink>Обратиться в службу поддержки</StyledSupplyLink>
        </StyledSupplyWrapper>
      </StyledForm>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    background: linear-gradient(90deg, #4D3C83 0%, #A35F9F 100%);

    
    height: 100%;
    margin: auto;
    color: #555;
  }
`

const StyledForm = styled.form`
  width: 600px;
  margin: auto;
  margin-top: 130px;
  padding: 50px 65px;
  background-color: #fff;
  border-radius: 20px;
  /* box-sizing: border-box; */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  
`

const StyledLogoHeader = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  
`


const StyledLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 168px;
  box-sizing: border-box;

  img {
    max-width: 100%;
    height: auto;
  }
`

const StyledHeaderLink = styled.div`
  width: 131px;
  height: 28px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #939393;

`

const StyledInputWrapper = styled.div`
  padding-top: 50px;
  display: flex;  
  flex-direction: column;

`

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #B0B3B5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  &:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #333333;
  }
  input {
    background-color: #EEEFEF;
  
    height: 60px;
    border-radius: 5px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;

    &:focus {
      box-shadow: 0 0 0 2px #262A29;
;
    }
  }
  
  & .errored {
    box-shadow: 0 0 0 2px red;
  }
  
  & .filled {
    transform: translate(0, 12px) scale(0.8);
  }
`

const StyledLabel = styled.label`
  font-family: Inter;
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333

`

const StyledButton = styled.button`
  display: block;
  background-color: #262A29;
  width: 100%;
  color: #fff;
  margin: 20px auto 30px;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 60px;
  padding: 0 60px;
  cursor: pointer;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;

`

const StyledSupplyWrapper = styled.div`
  display: flex;
  justify-content: center;  
`

const StyledSupplyLink = styled.label`
  width: 233px;
  height: 28px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid #262A29;
  
  &:hover {
    color: #4D3C83;
    border-bottom-color: #4D3C83;
  }

`

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: auto;
`

export default LoginPage;

