import { styled } from 'styled-components'
import { Flex, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';

const dataForSwitch = [
  { text: 'Сводная таблица' },
  { text: 'Группировка по всему' },
  { text: 'Официанты' }
]

const { Group } = Radio;


const TableHeaderViruchka = ({ tableCase, setTableCase }) => {
  const onChange = (e: RadioChangeEvent) => {
    setTableCase(e.target.value)
  };

  return (
    <TableHeaderWrapper>
      <TitleHeader>
        <p>{dataForSwitch[tableCase].text}</p>
      </TitleHeader>
      <StyledButtonWrapper>
        {/* <StyledButton onClick={()=>setTableCase(0)}>
          Группировки
        </StyledButton>
        <StyledButton onClick={()=>setTableCase(1)}>
          Сводная
        </StyledButton> */}
        <Flex vertical gap="small">
          <Group onChange={onChange} defaultValue={0} size='middle' >
            <Radio.Button value={0} >Сводная</Radio.Button>
            <Radio.Button value={1} >Группировки</Radio.Button>
            <Radio.Button value={2} >Официанты</Radio.Button>
          </Group>
        </Flex>
      </StyledButtonWrapper>
    </TableHeaderWrapper>
	)
}

export default TableHeaderViruchka;


const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #422795;
  padding: 30px 53px 30px 30px;
`

const TitleHeader = styled.div`
  width: 60%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  color: #FFFFFF;

  /* border: 1px solid #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
`

const StyledButtonWrapper = styled.div`
  display: flex;  
  gap: 20px;  
`