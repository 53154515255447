import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import type { GetProp, TableProps } from 'antd';
import { Button, Input, Tag } from 'antd/lib';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import dayjs from 'dayjs';
import { log } from 'console';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

const tagInputStyle: React.CSSProperties = {
  width: 70,
  height: 24,
  marginInlineEnd: 8,
  verticalAlign: 'top',
};

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const tagPlusStyle: React.CSSProperties = {
    height: 24,
    background: 'red',
    borderStyle: 'dashed',
};

const tagStyle: React.CSSProperties = {
    height: 24,
    borderStyle: 'dashed',
};

interface PropsClientModal {
  selectedClient: any
  offset?: any;
  keys?: number;
  setOpeningSettingsPopup: any;
}

moment().format();

const ContentModalMain: React.FC<PropsClientModal> = ({ selectedClient, keys, setOpeningSettingsPopup }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [dataMainUserVisites, setDataMainUserVisites] = useState({
    bonus: 0,
    balance: 0,
    visits: 0,
    visitLast: '2024-07-10'
  })
  const inputRef = useRef<InputRef>(null);
  
  useEffect(() => {
    if (loading ) {
      setLoading(false);
      const clientsUrl = `https://stage.proga.pro/api/shops/1/loyalty/clients/${selectedClient.id}/cards/spendStats`
      Promise.all([
        fetch(clientsUrl, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then((data) => {
          
          if (data && Array.isArray(data) && data.length > 0) {
           setDataMainUserVisites(data[0]) 
          }

        })
      setLoading(false);
    }
  }, [loading])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    // if (inputValue && !tags.includes(inputValue)) {
      // setTags([...tags, inputValue]);
    // }
    setInputVisible(false);
    setInputValue('');
  };

  const showInput = () => {
    setInputVisible(true);
  };

  return (
    <Wrapper>
      <NavbarTitleID>#{selectedClient.id}</NavbarTitleID>
      <ClientNameInfo>{selectedClient.fio}</ClientNameInfo>
      <StatisticWrap>
        <StyledTitle>СТАТИСТИКА:</StyledTitle>
        <StyledStatistic>
          <StyledStatisticItem>
            <StyledStatisticItemKey>посещений</StyledStatisticItemKey>
            <StyledStatisticItemValue>{dataMainUserVisites.visits}</StyledStatisticItemValue>
          </StyledStatisticItem>
          <StyledStatisticItem>
            <StyledStatisticItemKey>потраченные депозиты</StyledStatisticItemKey>
            <StyledStatisticItemValue>{-dataMainUserVisites.balance}</StyledStatisticItemValue>
          </StyledStatisticItem>
          <StyledStatisticItem>
            <StyledStatisticItemKey>потраченные бонусы</StyledStatisticItemKey>
            <StyledStatisticItemValue>{-dataMainUserVisites.bonus}</StyledStatisticItemValue>
          </StyledStatisticItem>
          <StyledStatisticItem>
            <StyledStatisticItemKey>последняя покупка</StyledStatisticItemKey>
            <StyledStatisticItemValue>{(dataMainUserVisites && dataMainUserVisites.visitLast) ? moment.utc(dataMainUserVisites.visitLast).format('DD.MM.YYYY') : '-'}</StyledStatisticItemValue>
          </StyledStatisticItem>
        </StyledStatistic>
      </StatisticWrap>
      
      <ClientInfoWrap>
        <StyledTitle>ОСНОВНОЕ</StyledTitle>
        <ClientInfoItem>
          <ClientInfoValue>{(selectedClient.sex) ? 'Мужской' : 'Женский'}</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoValue>{selectedClient.birthdate}</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoValue>{selectedClient.email}</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoValue>{selectedClient.phone}</ClientInfoValue>
        </ClientInfoItem>
      </ClientInfoWrap>
      <ClientInfoWrap>
      </ClientInfoWrap>
    </Wrapper>
   
  )
}

export default ContentModalMain;

const NavbarTitleID = styled.div`
  margin-left: 18px;
  margin-bottom: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  display: flex;
  align-items: center;

  color: #F7F7F7;
`

const ClientNameInfo = styled.div`
  margin-left: 18px;
  width: 124px;
  height: 56px;

  /* Заголовок */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */
  display: flex;
  align-items: center;

  color: #F7F7F7;
`
const ClientInfoValue = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;
  color: #F7F7F7;
`

const ClientInfoItem = styled.div`
  display: flex;
  flex-direction: row;  
`

const ClientInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 14px;
  gap: 12px;
  
`

const StyledStatistic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding-left: 10px;
  
`

const StyledStatisticItem = styled.div`
  display: flex;
  flex-direction: column;

  align-items: start;
  padding: 0px;

  
  
`

const StyledStatisticItemValue = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  /* identical to box height, or 202% */
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #F7F7F7;
`

const StyledStatisticItemKey = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;

  /* Текст/#FFFFFF 50% текст|иконки */
  color: rgba(255, 255, 255, 0.5);
`

const StatisticWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
  margin-bottom: 34px;
  padding: 10px 10px 18px 18px;
  background-color: #3D277E;
  width: 204px;
  gap: 16px;
  border-radius: 0px 5px 5px 0px;
  
`

const Wrapper = styled.div`
  /* Frame 85 */
  display: flex;
  flex-direction: column;
  width: 216px;
  padding: 12px 12px 0px 0px;
  margin-top: 10px;
  height: 595px;
  z-index: 1205;
  background-color: #2E1C65; 
  border-radius: 0px 10px 0px 0px;
  overflow-y: auto; /* Добавлено для прокрутки */
  
`

const StyledTitle = styled.div`
  width: 116px;
  height: 19px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #F7F7F7;
`
