import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { linesColorArray } from '../consts';
import { LegendContainer } from './LegendContainer';
  
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function (tooltipItem) {
  sum  += tooltipItem.parsed.y;
  });
  return 'Сумма: ' + new Intl.NumberFormat('ru-RU').format(sum);
};

export function LineChart({ dataSource }) {
  const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);

  if (dataSource) {
    const { range, values } = dataSource;
    const datas = {
      labels: range,
      datasets: values.map((item, ind) => ({
        label: item.key,
        data: item.values,
        borderColor: linesColorArray[ind].borderColor,
        backgroundColor: linesColorArray[ind].backgroundColor,
        fill: false,
        spanGaps: false, // Убедитесь, что пропуски не заполняются
        hidden: hiddenDatasets.includes(ind),
      })),
    };

  const options = {
    maintainAspectRatio: false, // отключено пропорциональное изменение сторон
    responsive: true,
    tension: 0.4,
    interaction: {
      mode: 'index' as const, // Исправлено
    },
    plugins: {
      tooltip: {
        mode: 'index' as const, // Исправлено
        intersect: true,
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
      },
      legend: {
        display: false, // Отключаем стандартную легенду
      },
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 2,
        clip: 20, // This will prevent the line from crossing the boundary
      },
      point: {
        hoverRadius: 6,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        hoverBorderWidth: 2,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        padding: {
          left: 100,
          right: 100,
        },
        ticks: {
          callback: (value) => range[value],
        },
      },
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        maxTicksLimit: 5,
      },
    },
  };

    return (
      <>
        <LegendContainer datas={datas} setHiddenDatasets={setHiddenDatasets} hiddenDatasets={hiddenDatasets} />
        <Line options={options} data={datas} />
      </>
      )
  }
  return <></>;
}
