import React from 'react';

export type Theme = 'light' | 'dark'
export interface User {
  email: string,
  roles: string,
}

export interface ApplicationInfo { 
  theme: Theme;
  setTheme: (theme: Theme) => void,
  user: any | undefined,
  setUser: (user: any) => void,
  onLogin: (user: any) => void;
  onLogout: () => void;
  inventGroups: any;
  setInventGroups: (inventGroupsItem: any) => void;
  rangeStart: string | undefined;
  setRangeStart: (date: string | undefined) => void;
  rangeEnd: string | undefined;
  setRangeEnd: (date: string | undefined) => void;
  periodDate: string | undefined;
  setSetPeriodDate: (date: string | undefined) => void;
  dateFilterMain: string | undefined,
  setDateFilterMain: (v: string | undefined) => void,
  dateRange: {
    from: number | undefined
    to: number | undefined
  } | undefined,
  setDateRange: (v: any) => void,
}

const ApplicationContext = React.createContext<ApplicationInfo>({
  theme: 'light',
  setTheme: () => { },
  user: undefined,
  setUser: () => { },
  onLogin: () => { },
  onLogout: () => { },
  inventGroups: undefined,
  setInventGroups: () => { },
  rangeStart: '2024-05-01',
  setRangeStart:  () => { },
  rangeEnd: '2024-05-07',
  setRangeEnd:  () => { },
  periodDate: '2024-05-07',
  setSetPeriodDate: () => { },
  dateFilterMain: '2024-05-07',
  setDateFilterMain: () => {},
  dateRange: {
    from: undefined,
    to: undefined,
  },
  setDateRange: () => {},
});

export const useApplication = () => React.useContext(ApplicationContext);

export default ApplicationContext;