import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import ContentModalMain from './content/ContentModalMain';
import ContentModalBayes from './content/ContentModalBayes';
import ContentModalHistory from './content/ContentModalHistory';
import ContentModalCards from './content/ContentModalCards';
import { Icons } from '../../../assets';

interface PropsClientModal {
  selectedClient: any,
  setOpeningSettingsPopup: any,
}

moment().format();


const ClientsModal: React.FC<PropsClientModal> = ({selectedClient, setOpeningSettingsPopup}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [switchIsOpen, setSwitchIsOpen] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<boolean[]>([]);
  const [cards, setCards] = useState<string[]>([]);
  const [selectOptions, setSelectedOptions] = useState<{
    value: string,
    label: any
  }[]>([])

  useEffect(() => {
    const clientsUrl = `https://stage.proga.pro/api/shops/1/loyalty/clients/${selectedClient.id}/cards`
      Promise.all([
        fetch(clientsUrl, {
          credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          let selVal: boolean[] = []
          const options = data.map(item => {
            selVal.push(true)
            return {
              value: item.code,
              label: <span key={item.template.id}>{(item.template && item.template.displayName) ? item.template.displayName : item.template.name} </span>
            }
          })
          setSelectedOptions(options)
          setSelectedValues(selVal)
        })
  }, [selectedClient])
  
  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const getOffset = (index) => {
    return (index - activeIndex === 0)
      ? 0
      : (index - activeIndex > 0)
        ? 1
        : -1
  };

  const swithHandler = (key: number) => {
    
    const newValues = [...selectedValues]
    newValues[key] = !newValues[key]
    setSelectedValues(newValues)
    setSwitchIsOpen(false)
  }

  useEffect(() => {
    let selectedCards: string[] = []
    selectedValues.map((i: boolean, ind: number) => {
      if (i && selectOptions && selectOptions[ind]) selectedCards.push(selectOptions[ind].value)
    })
    setCards(selectedCards)
  }, [selectedValues])

  // обработчки клика не населеторе, приводящий к закрытию
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      //   setSwitchIsOpen(false);
      // }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Wrapper>
      <SideWrap>
        <ContentModalMain keys={4} selectedClient={selectedClient} setOpeningSettingsPopup={setOpeningSettingsPopup} />
      </SideWrap>
      <OutsideWrap>
        <NavbarModalWrapper>
          <NavbarTitleModal>
            
            {/* <CardsNameContainer
              onClick={() => setSwitchIsOpen(!switchIsOpen)}
            >
              <span>Выбор карт</span>
              <CardsSelectorBtn>
                {switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
              </CardsSelectorBtn>
            </CardsNameContainer>
            {switchIsOpen && (
              <SelectorDropdown ref={dropdownRef}>
                  {selectOptions?.map((label: any, ind) => {
                    return (
                      <SelectorCardsNameContainer
                        key={ind}
                        onClick={() => swithHandler(ind)}
                      >
                        <label
                          style={{ marginRight: '8px', accentColor: '#C92722' }}
                          onClick={() => swithHandler(ind)}
                        />
                        <span>{label.label}</span>
                      </SelectorCardsNameContainer>)
                  })}
              </SelectorDropdown>
            )} */}
            <NavbarTabsModal>
              <RadioButton $isActive={activeIndex === 0} onClick={() => handleButtonClick(0)}><span>Покупки</span></RadioButton>
              <RadioButton $isActive={activeIndex === 1} onClick={() => handleButtonClick(1)}><span>История</span></RadioButton>
              <RadioButton $isActive={activeIndex === 2} onClick={() => handleButtonClick(2)}><span>Карты</span></RadioButton>
              <Highlight style={{ transform: `translateX(${activeIndex * 272}px)` }} />
            </NavbarTabsModal>
          </NavbarTitleModal>
        </NavbarModalWrapper>
        <ContentWrap>
          <FormWindow key={0} offset={getOffset(0)} >
            <ContentModalBayes keys={0} selectedCards={cards} selectedClient={selectedClient} setOpeningSettingsPopup={setOpeningSettingsPopup}/>
          </FormWindow>
          <FormWindow key={1} offset={getOffset(1)} >
            <ContentModalHistory keys={1} selectedClient={selectedClient} setOpeningSettingsPopup={setOpeningSettingsPopup}/>
          </FormWindow>
          <FormWindow key={2} offset={getOffset(2)} >
            <ContentModalCards keys={2} selectedClient={selectedClient} setOpeningSettingsPopup={setOpeningSettingsPopup}/>
          </FormWindow>
        </ContentWrap>
          
      </OutsideWrap>
      
    </Wrapper>
   
  )
}

export default ClientsModal;

const SideWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 216px;

`
const OutsideWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`

const CardsNameContainer = styled.div`
  display: flex;
  padding-left: 10px;
  z-index: 2100;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #1F1E24;
  text-transform: capitalize;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid #D6DCE9;
  border-radius: 8px;

  align-items: center;
  
  width: 140px;
  height: 28px;

  background: #000000;
  border-radius: 20px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  color: #F7F7F7;

& span {
    width: 112px;
  }
`

const CardsSelectorBtn = styled.div`
  margin-right: 8px;
  & svg path {
    fill: #A7A7A7;
  }
`

const SelectorCardsNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  width: 140px;
  padding: 0px;
  border-radius: 8px;
  color: #1F1E24;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.02em;
  cursor: pointer;
  margin-top: 0px;
  padding-left: 10px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  color: #F7F7F7;

  :hover {
    color: #ffffff;
  }
`

const SelectorDropdown = styled.div`
  width: 140px;
  max-height: 300px;
  margin-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 2200;
  top: 40px;
  left: 333px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgb(158 158 158 / 40%);

  background: #000000;
  border-radius: 20px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  color: #F7F7F7;

  ::-webkit-scrollbar {
    width: 14px;
    border-radius: 8px;
    background-color: inherit;
  }

  ::-webkit-scrollbar-thumb {
    height: 140px;
    background-color: #E7E7E7;
    border-radius: 8px;
    border: 5px solid #fff;
  }
`

const FormWindow = styled.div<{
  offset: any
}>`
  position: absolute;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${({ offset }) => offset * 120 - 0}%);
  
`;

const ContentWrap = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 1105;
  overflow-y: auto; /* Добавлено для прокрутки */
`

const Highlight = styled.div`
  position: absolute;
  background-color: #fa0;
  transform: translateX(0);
  transition: transform 0.3s ease;
  z-index: 1107;
  /* Avtive */

  /* Auto layout */
  width: 116px;
  height: 34px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  box-shadow: 0px 3px 3.4px rgba(0, 0, 0, 0.21);
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

`

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: flex-start;
  /* min-width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1105;
  background-color: #fff;
  /* box-sizing: border-box; */

/* Auto layout */
  padding: 0px 0px 0px;
  
  width: 917px;
  height: 605px;
  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #F7F7F7;
  /* #D6D6D6 графики */
  /* border: 0.5px solid #D6D6D6; */
  /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08); */
  border-radius: 5px;

  
`

const NavbarModalWrapper = styled.div`
  width: 680px;
  height: 46px;
  margin-bottom: 18px;
  

`

const NavbarTitleModal = styled.div`
  /* Frame 49 */
  display: flex;
  width: 100%;
  height: 28px;
  
`

const NavbarTitleName = styled.div`
  /* Артемьев Даниил */

  width: 250px;
  height: 28px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const NavbarTabsModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 680px;
  height: 46px;
  left: 226px;
  top: 10px;
  padding: 0px 10px;
  background: #F3F3F3;
  box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.1), inset 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
`

const RadioButton = styled.div<{
  $isActive: boolean;
}>`
  /* Frame 64 */
  display: flex;
  /* Auto layout */
  gap: 10px;
  z-index: 1110;
  width: 120px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  
  flex-grow: 0;
  color: ${props => props.$isActive ? 'white' : 'black'};
  transition: color 0.5s ease;

  cursor: pointer;
  & span {
    text-align: center;
    vertical-align: middle;
    
  }

  &:active {
    color: 'white';
  }
`
